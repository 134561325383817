import React from 'react'
import profile from './profile.jpg'
import './resume.scss'

const Resume = () => (
    <div id='resume'>
        <div className='mainDetails'>
            <div id='headshot'>
                <img src={profile} alt='' />
            </div>
            <div id='name'>
                <h1>Andrew Krippner</h1>
                <h2>Senior Design Engineer</h2>
            </div>
            <div id='contactDetails'>
                <ul>
                    <li>
                        e:
                        <a
                            href='mailto:hello@andrewkrippner.com'
                            target='_blank'
                        >
                            &nbsp;hello@andrewkrippner.com
                        </a>
                    </li>
                    <li>
                        m:
                        <a href='tel:7602160784' target='_blank'>
                            &nbsp;760.216.0784
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div id='mainArea'>
            <section>
                <div className='sectionTitle'>
                    <h1>About</h1>
                </div>
                <div className='sectionContent'>
                    <p>
                        Product development expert experienced in IVD, product
                        design, and software development.
                    </p>
                </div>
            </section>
            <section>
                <div className='sectionTitle'>
                    <h1>Work</h1>
                </div>
                <div className='sectionContent'>
                    <article>
                        <a href='https://biologicaldynamics.com'>
                            <h2>Biological Dynamics</h2>
                        </a>
                        <p className='subDetails'>April 2021 - Present</p>
                        <ul>
                            <li>
                                Leading effort to design and build a
                                semi-automated platform to capture, purify and
                                elute exosomes from plasma to detect stage 1 and
                                2 cancer
                            </li>
                        </ul>
                        <p></p>
                    </article>
                    <article>
                        <a href='https://toolboxmed.com'>
                            <h2>Toolbox Medical Innovations</h2>
                        </a>
                        <p className='subDetails'>October 2015 - April 2021</p>
                        <ul>
                            <li>
                                Concepted, designed, and consulted for several
                                CLIA waived POC devices, including SARS-CoV-2
                                diagnostics
                            </li>
                            <li>
                                Designed microfluidic/mesofluidic systems with
                                novel valving, pumping, and mixing technology
                            </li>
                            <li>
                                Designed and programmed a user interface for
                                building and executing scripts to control
                                automated assays
                            </li>
                            <li>
                                Designed consumable and breadboard instrument
                                for NGS Library Prep device
                            </li>
                            <li>Managed projects and teams</li>
                            <li>
                                Developed nasal irrigation and aspiration based
                                flu test
                            </li>
                            <li>
                                Designed key features in the first to market
                                marijuana breathalyzer
                            </li>
                            <li>
                                Invented novel peristaltic pump for application
                                in sepsis sample-to-answer platform
                            </li>
                            <li>Designed complex injection molds</li>
                        </ul>
                        <p></p>
                    </article>
                    <article>
                        <h2>SpotDrop</h2>
                        <p className='subDetails'>Sep 2016 - June 2017</p>
                        <p>
                            Designed novel remotely-adjustable drip emitter for
                            high-end wine grape irrigation. Mechanical design,
                            software, electronics, customer development.
                        </p>
                    </article>
                    <article>
                        <h2>HK Plastics Engineering</h2>
                        <p>Injection molding quality assurance</p>
                    </article>
                </div>
            </section>
            <section>
                <div className='sectionTitle'>
                    <h1>Skills</h1>
                </div>
                <div className='sectionContent'>
                    <ul className='keySkills'>
                        <li>System Design</li>
                        <li>Injection Molding</li>
                        <li>Microfluidics</li>
                        <li>In Vitro Diagnostics</li>
                        <li>ISO 13485</li>
                        <li>Project Management</li>
                        <li>Python</li>
                        <li>C/C++</li>
                        <li>Typescript (Node + React)</li>
                        <li>Solidworks</li>
                        <li>Microcontrollers</li>
                        <li>Rapid Prototyping+DFM</li>
                    </ul>
                </div>
            </section>
            <section>
                <div className='sectionTitle'>
                    <h1>Products</h1>
                </div>
                <div className='sectionContent'>
                    <article>
                        <a href='https://www.flowcircuits.com'>
                            <h2>Flow Circuits</h2>
                        </a>
                        <p>
                            Complete platform to interactively design, test and
                            build fluidic systems through scripting and
                            simulation
                        </p>
                    </article>
                    <article>
                        <a href='https://hvakr.com'>
                            <h2>HVAKR</h2>
                        </a>
                        <p>
                            HVAKR is a web application built to simplify the
                            HVAC mechanical engineering workflow
                        </p>
                    </article>
                    <article>
                        <a href='https://traqpad.com'>
                            <h2>Traqpad</h2>
                        </a>
                        <p>
                            Traqpad is a budgeting app built for checking
                            accounts and Bitcoin
                        </p>
                    </article>
                </div>
            </section>
            <section>
                <div className='sectionTitle'>
                    <h1>Education</h1>
                </div>
                <div className='sectionContent'>
                    <article>
                        <a href='https://calpoly.edu'>
                            <h2>California Polytechnic State University</h2>
                        </a>
                        <p className='subDetails'>
                            B.S. Mechanical Engineering
                        </p>
                        <p>
                            Emphasis on product design and creating market
                            applications for novel technologies
                        </p>
                    </article>
                </div>
            </section>
        </div>
    </div>
)

export default Resume
