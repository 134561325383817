import React from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'gatsby'
import { H2, Button } from '@blueprintjs/core'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import ResumePage from '../../resume/resume'
import '../../style/resume.scss'

const Resume = () => (
    <Layout page='/resume'>
        <SEO title='Resume' />
        <div className='Resume'>
            <div className='center'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 16,
                    }}
                >
                    <H2 style={{ flex: 1, margin: 0 }}>Resume</H2>
                    {!isMobile && (
                        <Link to='/resume/print'>
                            <Button
                                minimal
                                large
                                intent='primary'
                                icon='print'
                                text='Print'
                            />
                        </Link>
                    )}
                </div>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <ResumePage />
                </div>
            </div>
        </div>
    </Layout>
)

export default Resume
